import {
  Box,
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { getPageInfo } from '@/config/pages';
import { CourseCategory, ThemeColors } from '@/features/brand/types/brand';

export type BrandCourseCategoryCardRemarksOnlyProps = {
  courseCategoryCard: CourseCategory;
  themeColors: ThemeColors;
  isOption?: boolean;
};

export const BrandCourseCategoryCardRemarksOnly = ({
  courseCategoryCard: {
    courseCategoryImageUrl,
    courseCategoryName,
    courseCategoryDescription,
    courseCategoryId,
  },
  themeColors: {
    courseTextColor,
  },
  isOption,
}: BrandCourseCategoryCardRemarksOnlyProps) => {
  const isPc = useBreakpointValue({ base: false, lg: true });
  return (
    <Box
      bg={{ lg: 'white' }}
      w={{ lg: '85%' }}
      m={{ lg: 3 }}
      borderRadius={'15px'}
      _focus={{ outline: 'none' }}
      overflow={'hidden'}
    >
      <Box
        display={'flex'}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Box
          mr={{ base: 2, lg: 0 }}
          ml={{ base: 2, lg: 0 }}
          mb={{ base: 0, lg: 0 }}
          flex={1}
        >
          <Box
            textAlign={'center'}
            pt={{ base: 5, lg: 0 }}
          >
            <Image
              src={courseCategoryImageUrl}
              alt={courseCategoryName}
              height={280}
              width={420}
              sizes={'100vw'}
              style={{
                width: '100%',
                height: 'auto',
                objectPosition: '0 10%',
              }}
            />
          </Box>
        </Box>
        <Box
          flex={{ lg: 1, xl: 1.5 }}
        >
          <Box
            mx={{ base: 2, lg: 4, xl: 10 }}
            my={{ base: 2, lg: 4, xl: 10 }}
          >
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {isPc &&
                <Text
                  color={courseTextColor}
                  fontWeight={'bold'}
                  fontSize={'24px'}
                  textAlign={'left'}
                  px={{ base: 0, lg: 3 }}
                  pt={3}
                >
                  {courseCategoryName}
                </Text>}
            </Flex>
            <Text
              textStyle={'h5'}
              lineHeight={'1.5rem'}
              whiteSpace={'pre-line'}
              pt={3}
              px={{ lg: 3 }}
              textAlign={'justify'}
            >
              {courseCategoryDescription}
            </Text>
          </Box>
          {!isOption && courseCategoryId &&
            <Box
              textAlign={'center'}
              py={3}
            >
              <LinkButton
                color={courseTextColor}
                text={'このコースで予約'}
                w={{ base: '80%', lg: '50%' }}
                margin={'0 auto'}
                size={'lg'}
                linkUrl={getPageInfo('studio/course_category/{courseCategoryId}', { courseCategoryId: courseCategoryId, name: courseCategoryName })}
                textAlign={'center'}
              />
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};
